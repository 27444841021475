<script>
export default {
  name: "showTerms",

  data() {
    return {
      content: `<p>개인정보 처리방침</p>

<div style="margin-bottom: 1rem">
          <span>&nbsp;&ldquo;</span>
          <span id="market_name" style="margin: 0"></span>
          <span>&ldquo;(이하&nbsp;&ldquo;회사&rdquo;라고 한다)은 정보통신망 이용촉진 및 정보보호 등에 관한 법률,&nbsp;전기통신사업법,&nbsp;통신비밀보호법,&nbsp;개인정보보호법 등 개인정보와 관련된 법령상의 개인정보 보호 규정 및 방송통신위원회가 제정한 개인정보보호지침을 준수하고 있습니다.&quot;회사&quot;는 본 개인정보처리방침을 통하여 고객님이 &quot;회사&quot;에 제공하는 정보가 어떠한 용도와 방식으로 이용되고 있으며,&nbsp;보호되고 있는지 알려드리고자 합니다.</span>
</div>

    <p>제1조&nbsp;개인정보의 수집,&nbsp;이용 목적,&nbsp;항목 및 보유기간<br />
      &nbsp;&quot;회사&quot;는 본질적인 서비스 제공을 위해 최소한의 개인정보를&nbsp;&lsquo;필수동의&rsquo;하에 수집/이용하고 있습니다. 필수동의 항목에 동의하지 않는 경우에는 본질적인 서비스를 이용하실 수 없으며 원치 않으실 경우 &quot;회사&quot;와 서면으로 회원가입을 하셔야 합니다.<br />
      &quot;회사&quot;는 아래와 같은 목적을 위하여 개인정보를 수집 및 이용하고 있습니다.</p>

    <p>[개인정보의 수집,&nbsp;이용목적,&nbsp;항목 및 보유기간(필수)]<br />
      수집이용목적<br />
      &quot;회사&quot;가 개인을 식별하여 &ldquo;회원&rdquo; 개인과 &ldquo;회원&rdquo;이 소속된 상점, 거래처를 매칭시키기 위한 목적으로 수집합니다. 또한 제3자인 &ldquo;회사&rdquo;에 콘텐츠 전달 및 홍보 목적으로 제공됩니다.</p>

    <p>수집항목<br />
      회원 정보(아이디, 비밀번호, 이름, 연락처, 이메일)<br />
      업체 정보(사업자등록번호, 업체상호, 업체 대표번호, 업체 주소, 대표자, 대표자 연락처, 이메일)</p>

    <p>보유기간<br />
      회원의 서비스 중단 요청 시까지<br />
      ※&nbsp;법령에 따른 개인정보의 수집/이용,&nbsp;계약의 이행/편의증진을 위한 개인정보 처리위탁 및 개인정보 처리와 관련된 일반 사항은 서비스의 개인정보 처리방침에 따릅니다.<br />
      제2조&nbsp;개인정보 수집,&nbsp;이용 등에 대한 동의&nbsp;</p>

    <p>&quot;회사&quot;는 고객님이 개인정보 수집,&nbsp;이용 등의 내용에 대해 별도의 동의 절차를 마련하고 있으며,&nbsp;동의내용 확인 후&nbsp;&lsquo;동의&rsquo;&nbsp;란을 체크하면 개인정보 수집에 대해 동의한 것으로 봅니다.&nbsp;<br />
      동의하지 않을 경우 동의를 거부하실 수 있으며,&nbsp;동의 거부 시 서비스 이용이 불가합니다.<br />
      타인의 정보이용에 따른 책임은 당사자에게 있습니다.</p>

    <p>제3조&nbsp;개인정보의 보유 및 이용기간</p>

    <p>&quot;회사&quot;는 고객님이 동의를 해주시는 날로부터 고객님의 철회요청 시까지 기간에 한하여 고객님의 개인정보를 보유 및 이용하게 됩니다. 개인 정보의 수집 및 이용에 대한 동의를 철회하는 경우 해당 개인정보를 지체없이 파기합니다. 이 경우 개인정보는 어떠한 용도로도 열람 또는 이용할 수 없도록 처리됩니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간동안 보존합니다.<br />
      &nbsp;①&nbsp;상법 등 법령에 따라 보존할 필요성이 있는 경우&nbsp;<br />
      1.&nbsp;표시/광고에 관한 기록: 6개월&nbsp;(전자상거래등에서의 소비자보호에 관한 법률)&nbsp;<br />
      2.&nbsp;계약 또는 청약철회 등에 관한 기록: 5년&nbsp;(전자상거래등에서의 소비자보호에 관한 법률)<br />
      3.&nbsp;대금결제 및 재화 등의 공급에 관한 기록: 5년&nbsp;(전자상거래등에서의 소비자보호에 관한 법률)<br />
      4.&nbsp;소비자의 불만 또는 분쟁처리에 관한 기록: 3년(전자상거래등에서의 소비자보호에 관한 법률)<br />
      ②&nbsp;기타,&nbsp;고객님의 개별적인 동의가 있는 경우에는 개별 동의에 따른 기간까지 보관합니다.</p>

    <p>제4조&nbsp;개인정보의 파기절차 및 방법</p>

    <p>&nbsp;①&nbsp;파기절차:&nbsp;&nbsp;&quot;회사&quot;는 개인정보 보유 및 이용기간에 명시한 기간이 경과한 경우에 당해 개인정보를 지체없이 파기합니다.&nbsp;<br />
      &nbsp;②&nbsp;파기방법: &nbsp;종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.&nbsp;전자적 파일형태로 저장된 개인정보는 즉각 삭제합니다.</p>

    <p>제5조 고객의 권리와 그 행사방법</p>

    <p>&nbsp;고객은 언제든지 등록되어 있는 본인의 개인정보를 조회할 수 있으며 &quot;회사&quot;에 문의를 통하여 개인정보 수정 및 개인정보 이용에 대한 동의 등을 철회할 수 있습니다.&nbsp;고객님이 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다.&nbsp;&quot;회사&quot;는 고객님의 요청에 의해 해지 또는 삭제된 개인정보는&nbsp;&ldquo;개인정보의 보유 및 이용기간&rdquo;에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</p>

    <p>제6조&nbsp;개인정보의 기술적/관리적 보호 대책</p>

    <p>&quot;회사&quot;는 &ldquo;회원&rdquo;의 개인정보를 보호하기 위하여 다음과 같은 보호 대책을 시행하고 있습니다.<br />
      &nbsp;①&nbsp;해킹 및 컴퓨터 바이러스 등에 대비<br />
      &nbsp;&quot;회사&quot;는 해킹이나 컴퓨터 바이러스에 의하여 고객님들의 개인정보가 유출되거나 훼손되는 것을 막기 위하여 필요한 보안조치를 이용하고 있으며,&nbsp;더욱 향상된 보안조치를 확보할 수 있도록 가능한 모든 기술적 방법을 구비하기 위하여 노력하고 있습니다.&nbsp;<br />
      ②&nbsp;개인정보 처리자의 제한 및 교육<br />
      &nbsp;&quot;회사&quot;는 개인정보를 처리하는 직원을 최소한으로 제한하고 있으며,&nbsp;관련 직원들에 대한 교육을 수시로 실시하여 본 방침의 이행 및 준수 여부를 확인하고 있습니다.</p>

    <p>제7조&nbsp;개인정보보호책임자</p>

    <p>고객님들의 개인정보를 보호하고 개인정보와 관련된 불만 등을 처리하기 위하여 &quot;회사&quot;는 고객서비스담당 부서 및 개인정보보호책임자를 두고 있습니다.<br />
      고객님의 개인정보와 관련한 문의사항은 아래의 고객서비스담당 부서 또는 개인정보보호책임자에게 연락하여 주시기 바랍니다.<br />
      &nbsp;① 개인정보보호책임자&nbsp;:&nbsp;&quot;회사&quot;의 대표</p>

    <p>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.<br />
      &nbsp;① 개인정보침해신고센터&nbsp;(http://privacy.kisa.or.kr /&nbsp;국번없이&nbsp;118)<br />
      &nbsp;②&nbsp;&nbsp;대검찰청사이버수사과&nbsp;(http://www.spo.go.kr /&nbsp;국번없이&nbsp;1301)<br />
      &nbsp;③ 경찰청사이버안전국&nbsp;(http://cyberbureau.police.go.kr /&nbsp;국번없이&nbsp;182)</p>

    <p>제8조&nbsp;개인정보처리방침의 개정과 그 공지</p>

    <p>본 방침은&nbsp;2024년&nbsp;9월&nbsp;1일 부터 시행됩니다.&nbsp;<br />
      본 개인정보처리방침이 변경될 경우 &quot;회사&quot;는 변경 내용을 그 시행일&nbsp;7일 전부터 사이트에 공지할 예정입니다.</p>
    `
    }
  },

  mounted() {
    const pElement = document.getElementById('market_name');
    pElement.textContent = `${this.$route.query.market_name}`
  }
}
</script>

<template>
  <div class="col-sm-12 font-size-16 mt-2 mb-2 p-3" v-purify-html="content"/>
</template>

<style scoped>

</style>
